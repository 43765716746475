<template>
  <div class="box">
    <div class="card-box" id="scroll" ref="wrapper">
      <div
        class="card-box-item2"
        :style="{
          backgroundImage: 'url(' + cardList_cost_profit[0].lineColor + ')',
          backgroundSize: '100% 100%',
        }"
      >
        <div class="card-box-item-num-box" style="margin-top: 0">
          <div class="card-box-item-title">
            {{ cardList_cost_profit[0].title }}
            <el-tooltip
              class="item"
              style="
                width: 16px;
                height: 16px;
              "
              effect="dark"
              :content="cardList_cost_profit[0].remarks"
              placement="bottom"
            >
              <img src="@/assets/qs.png" />
            </el-tooltip>
          </div>

          <div class="card-box-item-num" style="margin-top:8px;">
            {{
              cardList_cost_profit[0].num
                ? String(cardList_cost_profit[0].num).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )
                : "--"
            }}
          </div>
        </div>
        <div class="to">
          <img :src="cardList_cost_profit[0].toUrl" alt="" />
        </div>
        <div class="jisuan">
          <div class="jisuan_center">
            <div class="left">
              <p class="text_1">实际销售额（万）</p>
              <p class="text_2">{{cardList_cost_profit[0].realityIncome? String(cardList_cost_profit[0].realityIncome).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )
                : "--"}}</p>
            </div>
            <div class="center">
              <p class="text_1"></p>
              <p class="text_2">—</p>
            </div>
            <div class="right">
              <p class="text_1">实际成本额（万）</p>
              <p class="text_2">{{cardList_cost_profit[0].realityCost? String(cardList_cost_profit[0].realityCost).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )
                : "--"}}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card-box-item"
        :style="{
          backgroundImage: 'url(' + cardList_cost_profit[1].lineColor + ')',
          backgroundSize: '100% 100%',
          width: '33%',
          flex:0.5
        }"
      >
        <div class="card-box-item-title-box">
          <div class="card-box-item-title">
            {{ cardList_cost_profit[1].title }}
          </div>
          <el-tooltip
            class="item"
            effect="dark"
            :content="cardList_cost_profit[1].remarks"
            placement="bottom"
          >
            <img src="@/assets/qs.png" />
          </el-tooltip>
        </div>
        <div class="card-box-item-num-box">
          <div class="card-box-item-num">
            {{ cardList_cost_profit[1].num }}%
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/utils/bus";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      scrollToX: 0,
      isShowRight: true,
      isShowLeft: false,
      timeType: 1, // 时间类型
      xData: {},
    };
  },
  computed: {
    ...mapGetters(["cardList_cost_profit"]),
  },

  created() {
    console.log(this.cardList_cost_profit);
    console.log("成本总展示");
    Bus.$on("data", (data) => {
      console.log("成本总展示");
      console.log(data);
    });
  },
  mounted() {},
  methods: {},
};
</script>


<style scoped lang='scss'>
.box {
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  margin-top: 24px;
}
.quekou {
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #262626;
  line-height: 22px;
}
.date {
  height: 22px;
  font-size: 14px;
  font-family: MiSans-Regular, MiSans;
  font-weight: 400;
  color: #0e7744;
  line-height: 22px;
}
.quekou_span {
  min-width: 18px;
  margin: 0 6px;
  height: 22px;
  font-size: 14px;
  font-family: MiSans-Regular;
  font-weight: 400;
  color: #ff7c00;
  line-height: 22px;
}

.svg-icon {
  width: 10px;
  height: 10px;
}
.card-box {
  width: 100%;
  display: flex;
}
.card-box:hover {
  cursor: pointer;
}
.card-box::-webkit-scrollbar {
  display: none;
}
.card-box-item2 {
  display: flex;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  padding: 24px;
  box-sizing: border-box;
  margin-right: 12px;
  white-space: nowrap;
  flex-basis: 274px;
  height: 156px;
  flex-shrink: 0;
  flex: 1;
  .card-box-item-num-box {
    display: flex;
    flex-direction: column;
    min-width: 33%;
  }
  .to {
    width: 40px;
    height: 100%;
    display: flex;
    img {
      width: 40px;
      height: 22px;
      display: inline-block;
      margin: auto;
    }
  }
}
.card-box-item {
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  padding: 24px;
  box-sizing: border-box;
  margin-right: 12px;
  white-space: nowrap;
  flex-basis: 274px;
  height: 156px;
  flex-shrink: 0;
  flex: 1;
}
.card-box-item:last-child {
  margin-right: 0;
}
.card-box-item-icon {
  width: 28px;
  height: 28px;
}
.card-box-item-icon img {
  width: 100%;
  height: 100%;
}
.card-box-item-title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card-box-item-title {
  font-size: 14px;
  color: #262626;
  margin-top: 4px;
}
.card-box-item-title0 {
  font-size: 14px;
  color: #ffffff;
  margin-top: 4px;
}
.card-box-item-title-box img {
  width: 16px;
  height: 16px;
  margin-left: 3px;
  margin-bottom: -4px;
}
.card-box-item-num-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 14px;
  font-family: MiSans-Demibold;
}
.card-box-item-num {
  font-weight: bold;
  color: #262626;
  font-size: 32px;
  font-family: MiSans-Demibold;
}
.card-box-item-num0 {
  font-weight: bold;
  color: #ffffff;
  font-size: 32px;
  font-family: MiSans-Demibold;
}
.card-box-item-unit {
  color: #00000085;
  font-size: 16;
  margin-left: 5px;
  margin-bottom: 5px;
}
.card-box-item-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.card-box-item-bottom-item {
  display: flex;
  flex-direction: row;
  height: 18px;
}
.rise {
  min-width: 47px;
  height: 100%;
  background: #ffffff;
  border-radius: 4px;
  opacity: 0.9;
  display: flex;
  line-height: 18px;
  margin-left: 2px;
  color: #ff5322;
}
.drop {
  min-width: 47px;
  height: 100%;
  background: #ffffff;
  border-radius: 4px;
  opacity: 0.9;
  display: flex;
  line-height: 18px;
  margin-left: 2px;
  color: #00a4ff;
}

.arrow {
  display: inline;
  width: 10px;
  height: 10px;
  margin-left: 2px;
  margin-top: 4px;
  margin-right: 4px;
}
.card-box-item-bottom-item-title {
  font-size: 12px;
  color: #595959;
  margin-right: 4px;
}
.card-box-item-bottom-item-title0 {
  font-size: 12px;
  color: #ffffff;
  margin-right: 4px;
}
.card-box-item-bottom-item-num1 {
  font-size: 12px;
  color: #ff5322;
}
.card-box-item-bottom-item-num2 {
  font-size: 12px;
  color: #00a1ff;
}
.select-box {
  position: absolute;
  top: 0;
  right: 0;
}
.select-box img {
  width: 18px;
  height: 18px;
}
.jisuan {
  display: flex;
  padding: 0 40px;
  box-sizing: border-box;
  .jisuan_center {
    height: 50px;
    flex: 1;
    display: flex;
    margin: auto;
    .center {
      margin: 0 40px;
    }
  }
}
.text_1 {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #595959;
  line-height: 12px;
}
.text_2 {
  font-size: 22px;
  font-family: MiSans-Medium, MiSans;
  font-weight: 500;
  color: #262626;
  line-height: 22px;
  margin-top: 5px;
}
@media screen and (min-width:2000px) {
    .card-box-item {
        border-radius: 15px;
    }
}
</style>