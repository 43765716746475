<template>
  <div class="select-card-child">
    <div
      class="select-card-bottom"
      v-watermark="{ text: text, textColor: 'rgba(180, 180, 180, 0.3)' }"
    >
      <div
        :id="'myChart4' + id"
        :style="{ width: '100%', height: '450px' }"
      ></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import colors from "@/utils/color";
import getRandomColor from "@/utils/jg";
export default {
  data() {
    return {
      xAxisData: [],
      yAxisData: [],
      legendData: [],
      seriesData: [],
      lineColor: [],
      itemStyle: {
        color: "",
      },
      text: "",
    };
  },
  props: ["id", "tissueComparison"],
  created() {
    if (sessionStorage.getItem("userInfo")) {
      this.text = JSON.parse(sessionStorage.getItem("userInfo")).name;
    }
    console.log("对比分析-柱状图", this.tissueComparison);
    this.legendData = this.tissueComparison.chartLabels;
    this.xAxisData = this.tissueComparison.dateList;
    this.lineColor = colors;
    this.tissueComparison.chartLabels.forEach((element, index) => {
      let temp = {};
      temp.value = this.tissueComparison.sumDataList[index];
      if (index > colors.length - 1) {
        temp.itemStyle = {
          color: getRandomColor.getRandomColor(),
          barBorderRadius: [74, 74, 0, 0],
        };
      } else {
        temp.itemStyle = {
          color: this.lineColor[index],
          barBorderRadius: [74, 74, 0, 0],
        };
      }
      if (temp.value > 0) {
        temp.itemStyle.barBorderRadius = [74, 74, 0, 0];
      } else {
        temp.itemStyle.barBorderRadius = [0, 0, 74, 74];
      }
      this.seriesData.push(temp);
    });
    console.log(this.legendData);
    console.log(this.seriesData);
  },
  mounted() {
    this.drawLine();
    // this.$watermark.set("水印内容",'myChart')
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById("myChart4" + this.id));
      // 绘制图表
      var option;
      option = {
        legend: {
          show: true,
          data: this.legendData,
          left: "left",
          itemWidth: 12,
          itemHeight: 12,
          type: "scroll",
          orient: "horizontal",
          itemGap: 24,
          icon: "roundRect",
        },
        color: this.lineColor,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          padding: 12,
          extraCssText: "box-shadow: 0px 2px 12px 0px rgba(64, 98, 117, 0.1);",
          textStyle: {
            color: "#262626", //设置文字颜色
            fontSize: 12,
          },
          backgroundColor: "#FFFFFF", //设置背景颜色
          borderColor: "#FFFFFF", //设置边框颜色
          formatter(params) {
            let html = "";
            for (let i in params) {
              html += `<span style="display:inline-block;width:10px;height:10px;background:${params[i].color};
border-radius: 2px;margin-right:4px;"></span><span>${params[i].name}:</span><p style="min-width:20px;margin-right:4px;margin-left:4px;text-align:right;display:inline-block;float:right">${params[i].value}<span style="margin-left:4px;display:inline-block;">万</span></p><br/>`;
            }
            // 返回值就是html代码可以使用标签
            return html;
          },
        },
        xAxis: {
          type: "category",
          itemStyle: {
            color: "#8C8C8C",
          },
          data: this.legendData,
          axisLine: { onZero: false },
          splitLine: { show: false },
          splitArea: { show: false },
          axisTick: {
            show: false, //y轴坐标点消失
          },
          axisLine: {
            show: false, //y轴线消失
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#8C8C8C",
            },
            interval: 0, //横轴信息全部显示
            rotate: 40, // 倾斜角度
            formatter: function (value) {
              return value.length > 10 ? value.substring(0, 10) + "..." : value;
            },
          },
        },
        yAxis: {
          type: "value",
          // show:false,
          axisTick: {
            inside: false,
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#8C8C8C",
            },
          },
          axisTick: {
            show: false, //y轴坐标点消失
          },
          axisLine: {
            show: false, //y轴线消失
          },
        },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "3%",
          containLabel: true,
        },
        series: [
          {
            data: this.seriesData,
            type: "bar",
            barMaxWidth: 30, //柱图宽度
          },
        ],
      };
      option && myChart.setOption(option);
      myChart.resize();
    },
  },
};
</script>

<style scoped lang="scss">
.select-card-child {
  background: #ffffff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: none;
  border-top-left-radius: none;
  width: 100%;
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 13px 24px 13px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .select-card-bottom {
    padding: 8px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .main {
    width: 100%;
    min-height: 500px;
  }
}
</style>
