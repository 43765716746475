var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{ref:"wrapper",staticClass:"card-box",attrs:{"id":"scroll"}},[_c('div',{staticClass:"card-box-item2",style:({
        backgroundImage: 'url(' + _vm.cardList_cost_profit[0].lineColor + ')',
        backgroundSize: '100% 100%',
      })},[_c('div',{staticClass:"card-box-item-num-box",staticStyle:{"margin-top":"0"}},[_c('div',{staticClass:"card-box-item-title"},[_vm._v(" "+_vm._s(_vm.cardList_cost_profit[0].title)+" "),_c('el-tooltip',{staticClass:"item",staticStyle:{"width":"16px","height":"16px"},attrs:{"effect":"dark","content":_vm.cardList_cost_profit[0].remarks,"placement":"bottom"}},[_c('img',{attrs:{"src":require("@/assets/qs.png")}})])],1),_c('div',{staticClass:"card-box-item-num",staticStyle:{"margin-top":"8px"}},[_vm._v(" "+_vm._s(_vm.cardList_cost_profit[0].num ? String(_vm.cardList_cost_profit[0].num).replace( /\B(?=(\d{3})+(?!\d))/g, "," ) : "--")+" ")])]),_c('div',{staticClass:"to"},[_c('img',{attrs:{"src":_vm.cardList_cost_profit[0].toUrl,"alt":""}})]),_c('div',{staticClass:"jisuan"},[_c('div',{staticClass:"jisuan_center"},[_c('div',{staticClass:"left"},[_c('p',{staticClass:"text_1"},[_vm._v("实际销售额（万）")]),_c('p',{staticClass:"text_2"},[_vm._v(_vm._s(_vm.cardList_cost_profit[0].realityIncome? String(_vm.cardList_cost_profit[0].realityIncome).replace( /\B(?=(\d{3})+(?!\d))/g, "," ) : "--"))])]),_vm._m(0),_c('div',{staticClass:"right"},[_c('p',{staticClass:"text_1"},[_vm._v("实际成本额（万）")]),_c('p',{staticClass:"text_2"},[_vm._v(_vm._s(_vm.cardList_cost_profit[0].realityCost? String(_vm.cardList_cost_profit[0].realityCost).replace( /\B(?=(\d{3})+(?!\d))/g, "," ) : "--"))])])])])]),_c('div',{staticClass:"card-box-item",style:({
        backgroundImage: 'url(' + _vm.cardList_cost_profit[1].lineColor + ')',
        backgroundSize: '100% 100%',
        width: '33%',
        flex:0.5
      })},[_c('div',{staticClass:"card-box-item-title-box"},[_c('div',{staticClass:"card-box-item-title"},[_vm._v(" "+_vm._s(_vm.cardList_cost_profit[1].title)+" ")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.cardList_cost_profit[1].remarks,"placement":"bottom"}},[_c('img',{attrs:{"src":require("@/assets/qs.png")}})])],1),_c('div',{staticClass:"card-box-item-num-box"},[_c('div',{staticClass:"card-box-item-num"},[_vm._v(" "+_vm._s(_vm.cardList_cost_profit[1].num)+"% ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center"},[_c('p',{staticClass:"text_1"}),_c('p',{staticClass:"text_2"},[_vm._v("—")])])
}]

export { render, staticRenderFns }